import { CDPRuntimeEventApp } from './cdp/cdp-app-types'
import { logError } from './log'
import { CDPEventPostProcessor } from './cdp/cdp-types'

// for this function to work, the CDP client must be initialized in useGlobalCdpClient()
export async function cdpTrackEvent(
  event: CDPRuntimeEventApp,
  processor?: CDPEventPostProcessor
): Promise<void> {
  if (!window.cdpClient) {
    logError('CDP client not initialized')
    return
  }

  await window.cdpClient.track(event, processor)
}
