import { DiscriminateUnion } from 'shared-definitions/types'
import type { AppEvent } from 'shared-code/app-events'
import { clickedObjectIDsAfterSearch, convertedObjectIDs } from 'shared-code/algolia-insights'
import { cdpTrackEvent } from './cdp'
import { CDPEvent } from './cdp/cdp-types'
import { fullUrl } from './format'
import { gtmDatalayer } from './gtm'

export type MapAppEventListeners<T extends Record<K, string>, K extends keyof T, R> = {
  [V in T[K]]: ((props: DiscriminateUnion<T, K, V>) => R)[]
}

const algoliaConverted = new Set<string>()

export const appEventListeners: MapAppEventListeners<AppEvent, 'event', void> = {
  'form-started': [
    e => {
      void cdpTrackEvent({
        name: 'Form Started',
        properties: {
          formName: e.payload.name,
          formId: e.payload.id ?? e.postMeta.postId?.toString() ?? null,
        },
      })
    },
  ],
  'form-success': [
    e => {
      void cdpTrackEvent({
        name: 'Form Completed',
        properties: {
          success: true,
          formName: e.payload.name,
          formId: e.payload.id ?? e.postMeta.postId?.toString() ?? null,
        },
      })
    },
    e => {
      if (e.payload.name === 'newsletters-signup') {
        void gtmDatalayer({
          event: 'newsletters_subscribe',
          event_category: 'Newsletters',
          event_label: 'Newsletters subscribe event',
        })

        void gtmDatalayer({
          type: 'track',
          event: 'List Signup',
          properties: {
            url: fullUrl(e.url),
            cta: e.payload.label,
            heading: e.payload.heading,
            email: e.payload.email,
          },
        })
      }
    },
  ],
  'form-failure': [
    e => {
      void cdpTrackEvent({
        name: 'Form Completed',
        properties: {
          success: false,
          formName: e.payload.name,
          formId: e.payload.id ?? e.postMeta.postId?.toString() ?? null,
        },
      })
    },
  ],
  'conversation-posted': [
    e => {
      void cdpTrackEvent({
        name: 'Conversation Posted',
        properties: {
          conversationId: e.containerId,
          messageLength: e.content.length,
        },
      })
    },
  ],
  'user-authenticated': [
    e => {
      function processor(ev: CDPEvent): CDPEvent {
        return { ...ev, userId: e.userId }
      }

      if (e.justRegistered) {
        void cdpTrackEvent(
          {
            name: 'Signup Completed',
            properties: {
              signupMethod: e.connection,
            },
          },
          processor
        )
      }

      // send always
      void cdpTrackEvent(
        {
          name: 'Login',
          properties: {
            loginMethod: e.connection,
          },
        },
        processor
      )
    },
  ],
  'discovery-sort-changed': [],
  'product-list-viewed': [],
  'product-list-clicked': [],
  'widget-interaction': [
    e => {
      if (e.widgetType === 'Main Menu') {
        void cdpTrackEvent({
          name: 'Navigation Clicked',
          properties: {
            navigationItem: e.label,
            pageUrl: fullUrl(e.url),
          },
        })
      }
    },
  ],
  'page-article-viewed': [
    e => {
      void cdpTrackEvent({
        name: 'Page Viewed',
        properties: {
          pageUrl: fullUrl(e.url),
          articleId: e.articleId,
        },
      })
    },
  ],
  'page-category-viewed': [],
  'page-tag-clicked': [],
  'discovery-changed': [],
  'top-menu-tab-clicked': [
    e => {
      void cdpTrackEvent({
        name: 'Navigation Clicked',
        properties: {
          navigationItem: e.label,
          pageUrl: e.url ? fullUrl(e.url) : null,
        },
      })
    },
  ],
  'outbound-link': [
    e => {
      void cdpTrackEvent({
        name: 'Link Clicked',
        properties: {
          linkUrl: fullUrl(e.linkUrl),
          linkText: e.linkText,
        },
      })
    },
    ({ postMeta, desc }) => {
      if (!postMeta.postId) {
        return
      }

      const categories = postMeta.categories.map(i => i.label.toLowerCase())
      const hitAlgolia = ['reviews', 'the best', 'deals'].some(i => categories.includes(i))

      if (hitAlgolia) {
        void convertedObjectIDs({
          eventName: desc,
          objectIDs: [postMeta.postId.toString()],
        })
      }
    },
  ],
  'poll-login-triggered': [
    ({ postMeta }) => {
      if (!postMeta.postId) {
        return
      }

      void convertedObjectIDs({
        eventName: 'Poll login triggered',
        objectIDs: [postMeta.postId.toString()],
      })
    },
  ],
  'poll-voted': [
    ({ postMeta }) => {
      if (!postMeta.postId) {
        return
      }

      void convertedObjectIDs({
        eventName: 'Poll voted',
        objectIDs: [postMeta.postId.toString()],
      })
    },
  ],
  'search-result-clicked': [
    ({ queryId, objectId, position, desc }) => {
      void clickedObjectIDsAfterSearch({
        eventName: desc,
        queryID: queryId,
        objectIDs: [objectId],
        positions: [position],
      })
    },
  ],
  'page-scrolled': [
    ({ postMeta, progress }) => {
      if (!postMeta.postId) {
        return
      }

      const cacheKey = makeKey(postMeta.postId, 'page-scrolled')
      if (algoliaConverted.has(cacheKey)) {
        return
      }

      if (progress < 0.5) {
        return
      }

      if (progress >= 0.7) {
        const categories = postMeta.categories.map(i => i.label.toLowerCase())
        const hitAlgolia = ['how to'].some(i => categories.includes(i))

        if (hitAlgolia) {
          algoliaConverted.add(cacheKey)
          void convertedObjectIDs({
            eventName: 'Post scrolled',
            objectIDs: [postMeta.postId.toString()],
          })
          return
        }
      }

      if (progress >= 0.5) {
        const categories = postMeta.categories.map(i => i.label.toLowerCase())
        const hitAlgolia = ['reviews', 'features', 'news'].some(i => categories.includes(i))

        if (hitAlgolia) {
          algoliaConverted.add(cacheKey)
          void convertedObjectIDs({
            eventName: 'Post scrolled',
            objectIDs: [postMeta.postId.toString()],
          })
        }
      }
    },
  ],
}

export function mergeAppEventsListeners<T = MapAppEventListeners<AppEvent, 'event', void>>(
  base: T,
  additional: Partial<T>
): T {
  const result = { ...base }
  for (const key in additional) {
    const newVal = additional[key]
    const oldVal = result[key]

    if (newVal) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      result[key] = [...newVal, ...oldVal]
    }
  }
  return result
}

function makeKey(postId: number, event: string): string {
  return `${event}${postId}`
}
