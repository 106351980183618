import { DSDealPrice, DSNCDealMSRP } from 'shared-definitions/types'

export function msrpFormat(msrp: DSNCDealMSRP): string {
  return 'MSRP: ' + wrapWithCurrency(priceFormat(msrp.price), msrp.currency)
}

export function wrapWithCurrency(priceText: string, currency: string): string {
  return `${currency}${priceText}`
}

type PriceForFormat = Pick<DSDealPrice, 'current' | 'isFree' | 'currency'>
export function priceCurrencyText(input: PriceForFormat, fraction = 2): string {
  return input.isFree || !input.current
    ? 'FREE'
    : wrapWithCurrency(priceFormat(input.current, fraction), input.currency)
}

export function priceFormat(priceValue: number, fraction = 2): string {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  })
  return formatter.format(priceValue)
}

export function timeFormat(seconds: number): string {
  if (seconds >= 3600) {
    return '59:59'
  }
  const date = new Date(0)
  date.setSeconds(seconds)
  return date.toISOString().substring(14, 19)
}

export function padZeros(num: number, size: number): string {
  const numStr = `${num}`
  if (numStr.length > size) {
    return numStr
  }
  return `000000000${numStr}`.slice(-size)
}

export function formatNumberDecimals(num: number, digits: number): string {
  const formatted = num.toFixed(digits)
  return parseFloat(formatted).toString()
}

export function formatFileSize(size: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let i = 0
  while (size >= 1024 && i < units.length - 1) {
    size /= 1024
    i++
  }
  return `${size.toFixed(2)} ${units[i]}`
}

export function fullUrl(url: string): string {
  return new URL(url, window.location.origin).href
}
